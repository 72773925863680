<template>
    <div class="publicEchart">
        <div class="publicEchartIn" :id="myChart"></div>
    </div>
</template>

<script>

    import * as echarts from 'echarts';

    export default {
        name: "publicEchart",
        data() {
            return {
                myChart: '',
            }
        },
        props: ['chartName', 'xAxis', 'series'],
        created() {
            let chartName = "myChart" + Date.now();
            if (this.chartName) {
                chartName += this.chartName
            }
            this.myChart = chartName;
            let idName = '#' + this.myChart
            this.$nextTick(() => {
                // 基于准备好的dom，初始化echarts实例
                var myChart = echarts.init(document.querySelector(idName));
                // 绘制图表
                myChart.setOption({
                    color: ['#5B9BD5', '#ED7D31', '#A5A5A5', '#FFC000', '#5B9BD5', '#ED7D31', '#A5A5A5', '#FFC000'],
                    tooltip: {
                        trigger: 'axis'
                    },
                    xAxis: {
                        type: 'category',
                        data: this.xAxis
                    },
                    yAxis: {
                        type: 'value'
                    },
                    series: this.series
                });
            })
        }
    }
</script>

<style scoped>

    .publicEchart {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
    }

    .publicEchartIn {
        width: 100%;
        height: 100%;
    }

</style>
